import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { margin } from "ds_legacy/materials/metrics";
import { Body } from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import { EmptiableField } from "dsl/organisms/Infos/PatientInformation/shared";
import { useTranslations } from "translations";

const isGrantChecked = (value: number | null | undefined): boolean =>
  (value && value > 0) || false;

export default function ReliefServiceGrant({
  oldValue,
  testId,
  value,
  withDiff,
}: {
  oldValue?: number | null | undefined;
  testId: string;
  value: number | null | undefined;
  withDiff: boolean;
}) {
  const getOntology = useGetOntology();
  const translations = useTranslations();
  const print = usePrint();

  const title = translations.patient.reliefServices;
  const isChecked = isGrantChecked(value);
  const ontolgyValue = getOntology({ type: "reliefServices", key: value });
  const ontolgyOldValue = getOntology({
    type: "reliefServices",
    key: oldValue,
  });

  if (print) {
    return isChecked ? (
      <Body>
        {title}
        {value && `${translations.general.colon} ${ontolgyValue}`}
      </Body>
    ) : null;
  }

  return isChecked ? (
    <VerticalLayout margin={margin(0)}>
      <EmptiableField
        title={value ? title : ""}
        value={value ? ontolgyValue : title}
        oldValue={oldValue ? ontolgyOldValue : title}
        withDiff={withDiff}
        testId={testId}
      />
    </VerticalLayout>
  ) : null;
}

import Translations from "translations/types";

export function getPayersLabels(translations: Translations) {
  return {
    civil_servants_aid:
      translations.patient.patientInformation.civilServantsAid,
    civil_servants_aid_description:
      translations.patient.patientInformation.civilServantsAidDescription,
    free_from_supplementary_payment_state:
      translations.patient.patientInformation.freeFromSupplementaryPayment,
    further_information:
      translations.patient.patientInformation.payersMoreInformations,
    insurance: translations.patient.patientInformation.payersInsurance,
    insurance_description: translations.patient.insuranceProvider,
    other_payer: translations.patient.patientInformation.payersOther,
    other_payer_description:
      translations.patient.patientInformation.payersOtherDescription,
    public_accident_insurance:
      translations.patient.patientInformation.publicAccidentInsurance,
    public_accident_insurance_description:
      translations.patient.patientInformation
        .publicAccidentInsuranceDescription,
    public_pension: translations.patient.patientInformation.publicPension,
    public_pension_description:
      translations.patient.patientInformation.publicPensionDescription,
    selected_payment_method: translations.patient.patientInformation.payers,
    self_payer: translations.patient.patientInformation.selfPayer,
    self_payer_description:
      translations.patient.patientInformation.selfPayerDescription,
    social_service_department:
      translations.patient.patientInformation.socialServiceDepartment,
    social_service_department_description:
      translations.patient.patientInformation
        .socialServiceDepartmentDescription,
    supplementary_insurance_description:
      translations.patient.patientInformation.supplementaryInsuranceDescription,
    supplementary_insurance:
      translations.patient.patientInformation.supplementaryInsurance,
    supplementary_payment_state:
      translations.patient.patientInformation.supplementaryPayment,
    supplementary_payment:
      translations.patient.patientInformation.supplementaryPayment,
    supplementary_payment_description:
      translations.patient.patientInformation.supplementaryPaymentDescription,
  } as const;
}

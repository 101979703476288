import { SHORT_TERM_CARE_GRANT_NONE } from "core/consts";
import { GetOntologyType } from "core/types";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { margin } from "ds_legacy/materials/metrics";
import { Body } from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import { EmptiableField } from "dsl/organisms/Infos/PatientInformation/shared";
import { useTranslations } from "translations";

const isGrantChecked = (value: number | null | undefined): boolean =>
  (value && value > 0) || false;

export default function ShortTermCareGrant({
  getOntology,
  oldValue,
  testId,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  oldValue?: number | null | undefined;
  testId: string;
  value: number | null | undefined;
  withDiff: boolean;
}) {
  const translations = useTranslations();
  const isChecked = isGrantChecked(value);
  const hasValue = value && value !== SHORT_TERM_CARE_GRANT_NONE;
  const hasOldValue = oldValue && oldValue !== SHORT_TERM_CARE_GRANT_NONE;
  const print = usePrint();
  const title = translations.patient.shortTermCareGrant;

  if (print) {
    if (!isChecked) return null;
    return (
      <Body>
        {title}
        {hasValue &&
          `${translations.general.colon} ${getOntology({
            type: "shortTermGrant",
            key: value,
          })}`}
      </Body>
    );
  }

  return isChecked ? (
    <VerticalLayout margin={margin(0)}>
      <EmptiableField
        testId={testId}
        title={hasValue ? title : ""}
        value={
          hasValue ? getOntology({ type: "shortTermGrant", key: value }) : title
        }
        oldValue={
          hasOldValue
            ? getOntology({ type: "shortTermGrant", key: oldValue })
            : title
        }
        withDiff={withDiff}
      />
    </VerticalLayout>
  ) : null;
}
